import React from 'react';

const Footer = () => {
    return (
        <footer className="blog-footer">
            <p>Footer</p>
        </footer>
    );
};

export default Footer;