import React from 'react';

const StocktwitStockCard = (props) => {
    return (
        <tr>
            <th scope="row">Rank</th>
            <th>Ticker</th>
            <th>Name</th>
            <th>Something Else</th>
        </tr>
    );
};

export default StocktwitStockCard;